import React, { useEffect } from 'react';
import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import LazyHydrate from 'react-lazy-hydration';
import { useDispatch } from 'react-redux';
import HomepageVideo from 'components/Homepage/HomepageVideo';

const HomepageUniqueSellingPoints = dynamic(
  () => import('components/Homepage/HomepageUniqueSellingPoints')
);
const HomepageStoreContent = dynamic(
  () => import('components/Homepage/HomepageStoreContent')
);
const HomepageReviews = dynamic(
  () => import('components/Homepage/HomepageReviews')
);
const RecommendedProductsSlider = dynamic(
  () => import('components/Others/RecommendedProductsSlider')
);
const HomepageAcceptedHealthFunds = dynamic(
  () => import('components/Homepage/HomepageAcceptedHealthFunds')
);
const CommunityShowcase = dynamic(() => import('components/ProductDetail/CommunityShowcase'), { ssr: false });

import { getHomePageId } from '../constants/woocommerceParentCateoryIds';
import { handleregionapollo } from '../functions/handleregionapollo';
import { GET_HOMEPAGE } from '../gqlStrings/queries/getHomepage';
import { setIsNavbarBlack } from '../redux/features/auth/authSlice';
import HomepageCategoriesV2 from 'components/Homepage/HomapageCategoriesV2';
import useDeviceType from 'hooks/useDeviceType';
import notionStoreDataService from 'services/notion.stores.service';
import { NotionStore, StoreCountry, StoreState } from 'constants/store.dataType';
import { getSeoMetaTags } from 'utils/common.utils';
import { SeoMetaTags } from 'constants/dataTypes';
import GlobalSeoMetaTags from 'components/GlobalSeoMetaTags/GlobalSeoMetaTags';
import HomepageCampaignBanners, { PromoBannersObject } from 'components/Homepage/HomepageCampaignBanners';

export default function Home({
  page,
  stores,
  seoMetaTags,
  desktopVideoUrl,
  mobileVideoUrl,
  videoAltText,
  promoBanners
}: {
  page: any;
  stores: any;
  seoMetaTags: SeoMetaTags;
  desktopVideoUrl: string;
  mobileVideoUrl: string;
  videoAltText: string;
  promoBanners: PromoBannersObject;
}) {
  const dispacth = useDispatch();
  const deviceType = useDeviceType();

  useEffect(() => {
    dispacth(setIsNavbarBlack(page.homePageField.navbarMenuColorIsBlack));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head>
        <title>Dresden Vision | Glasses Made Simple</title>
        <meta name="description" content="Affordable, sustainable, Australian-made eyewear. Distance or reading glasses from $100; multifocals from $250." />
        <meta property="og:description" content="Affordable, sustainable, Australian-made eyewear. Distance or reading glasses from $100; multifocals from $250." />
        <meta property="og:image" content="https://cdn.dresden.vision/static-images/dresdenvision.webp" />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta property="twitter:image" content="https://cdn.dresden.vision/static-images/dresdenvision.webp" />
        <meta name="twitter:description" content="Affordable, sustainable, Australian-made eyewear. Distance or reading glasses from $100; multifocals from $250." />
        <link rel="preload" href={(deviceType.isMobile ? mobileVideoUrl : desktopVideoUrl) + '.webp'} as="image" type="image/webp" />
        <link rel="preload" href={deviceType.isMobile ? mobileVideoUrl : desktopVideoUrl} as="video" type="video/mp4" />


      </Head>
      <HomepageVideo desktopVideoUrl={desktopVideoUrl} mobileVideoUrl={mobileVideoUrl} videoAltText={videoAltText} />
      <HomepageCampaignBanners promoBanners={promoBanners} />
      <HomepageCategoriesV2 />
      <LazyHydrate whenVisible>
        <HomepageStoreContent stores={stores} />
      </LazyHydrate>
      <LazyHydrate whenVisible>
        <HomepageUniqueSellingPoints />
      </LazyHydrate>
      <LazyHydrate whenVisible>
        <CommunityShowcase />
      </LazyHydrate>



      <LazyHydrate whenVisible>
        <HomepageReviews />
      </LazyHydrate>
      <LazyHydrate whenVisible>
        <RecommendedProductsSlider />
      </LazyHydrate>
      <LazyHydrate whenVisible>
        <HomepageAcceptedHealthFunds />
      </LazyHydrate>
      <GlobalSeoMetaTags seoMetaTags={seoMetaTags} />
    </>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const { data } = await handleregionapollo(locale).query({
    query: GET_HOMEPAGE,
    variables: {
      id: getHomePageId(locale)
    },
    context: { region: locale }
  });

  /*
  const {
    data: { posts: homepageSlidePosts }
  } = await handleregionapollo(locale).query({
    query: GET_HOMEPAGE_SLIDER
  });

  const banerSlideList: HomepageSliderSlideProps[] =
    homepageSlidePosts?.edges?.map((edge: any) => {
      const { homePageBannerField, content, id, title } = edge?.node;
      return {
        id,
        title,
        content,
        contentColor: homePageBannerField?.titleColor,
        order: homePageBannerField?.bannerOrder || 999,
        region: homePageBannerField?.region,
        image: {
          default: homePageBannerField?.bannerImageWeb?.mediaItemUrl,
          mobile: homePageBannerField?.bannerImageMobile?.mediaItemUrl,
          tablet:
            homePageBannerField?.bannerImageTablet?.mediaItemUrl ||
            homePageBannerField?.bannerImageMobile?.mediaItemUr
        },
        button: {
          background: homePageBannerField?.buttonBackground,
          enabled: homePageBannerField?.buttonOpen,
          text: homePageBannerField?.buttonText,
          textColor: homePageBannerField?.buttonTextColor,
          path: homePageBannerField?.buttonUrl
        },
        visibleDateRange: {
          start: homePageBannerField?.publishDate,
          end: homePageBannerField?.unpublishDate
        },
        newUserWithoutOrdered: homePageBannerField?.newUserWithoutOrdered,
        canGuestSee: homePageBannerField?.canGuestSee,
      };
    });
    */
  /*
    const storesResponse = await fetch(
      process.env.NEXT_PUBLIC_LARAVEL_URL + '/stores'
    );*/


  const stores = await notionStoreDataService.getStores();
  //console.log('storess', JSON.stringify(storess));


  const allstores =
    locale === 'ca'
      ? stores.reverse()
      : stores;


  allstores.forEach((country: any) => {
    country.states.forEach((state: any) => {
      state.stores = state.stores.filter((store: any) => store.slug !== "newtown");
    });
  });

  const countryList: any[] = [];
  allstores.map((countryData: StoreCountry) => {

    const stateList: any[] = [];
    countryData.states.map((stateData: StoreState) => {

      const storeList: any[] = [];
      stateData.stores.map((store: NotionStore) => {
        storeList.push({
          "name": store.name,
          "path": "/stores/" + store.slug,
          "googleReviewUrl": store.googleReviewLink
        });
      });

      stateList.push({
        "code": stateData.state.name,
        "name": stateData.state.name,
        "path": '/stores/' + stateData.state.slug,
        "storeList": storeList
      });

    })
    countryList.push({
      "code": countryData.country.name.substring(0, 2).toUpperCase(),
      "name": countryData.country.name,
      "stateList": stateList
    });


  })


  if (!data) {
    return {
      notFound: true
    };
  }

  //const ogImage = banerSlideList?.[0]?.image?.default;

  const seoMetaTags = await getSeoMetaTags('/index', locale);

  // Add these lines to provide video URLs
  const desktopVideoUrl = "https://cdn.dresden.vision/video/home-video/seethingsbetter3.mp4";
  const mobileVideoUrl = "https://cdn.dresden.vision/video/home-video/seethingsbetter-mobile.mp4";

  // Australia Video Alt Text
  const australiaVideoAltText = "Video presents stylish and customisable frames for glasses for Australian customers. These frames for glasses come in many colours and have interchangeable arms. Available in various sizes to suit everyone. These Australian-made frames come with a 10-year warranty, offering long-lasting durability and a personalised touch. Choose from a wide range of vibrant frame colours and enjoy clear vision with frames designed for your style. Get your trendy pair at Dresden Vision and experience the versatility of glasses with interchangeable arms that suit any face or look.";

  // New Zealand Video Alt Text
  const newZealandVideoAltText = "Video presents fashionable, Australian-made frames for glasses for customers in New Zealand. These frames are customisable and have interchangeable arms. They also come in all sorts of vibrant colours and sizes. Whether you need frames for every occasion or want to make a bold fashion statement, we offer trendy glasses with a 10-year warranty. Find your perfect fit with our colourful, stylish frames and see the world clearer. Get your pair of Dresden Vision Frames today—crafted for everyone's unique style and comfort.";

  // Canada Video Alt Text
  const canadaVideoAltText = "Video presents stylish and customisable frames for glasses for Canadian customers. With a range of colourful frames and interchangeable arms in various sizes, these frames are of high-quality craftsmanship. Offering a perfect fit for everyone, these durable frames are backed by a 10-year warranty. See the world clearer with Dresden Vision eyewear. Elevate your style with modern, custom frames, and get your pair today.";

  // US - International Video Alt Text
  const usVideoAltText = "The video showcases stylish, customisable glasses frames designed for all. These frames come in a variety of colors, features and interchangeable arms, offering a personalised touch. Available in multiple sizes to fit everyone, these frames are built to last with a 10-year warranty. Explore a vibrant selection of frame colors and enjoy clear vision with frames tailored to your style. Visit Dresden Vision online to find your trendy pair and experience the versatility of Dreden Vision Frames for glasses to suit any face or style";

  const videoAltText = locale === 'au' ? australiaVideoAltText : locale === 'nz' ? newZealandVideoAltText : locale === 'ca' ? canadaVideoAltText : usVideoAltText;

  /*
  Dresden Vision Australia: "Dresden Vision glasses with sleek black frames on a bright mustard yellow background, showcasing the minimalist style popular in Australia."

Dresden Vision New Zealand: "Dresden Vision’s classic black frames set against a bold yellow backdrop, reflecting the stylish yet practical eyewear favored in New Zealand."

Dresden Vision Canada: "Dresden Vision black glasses with a warm golden background, highlighting the simple and durable design appreciated by Canadians."

Dresden Vision United States: "Dresden Vision’s sophisticated black frames on a sunny yellow surface, representing the blend of fashion and function favored in the United States." */

  const firstOrderBannerAltText = locale === 'au' ? "Dresden Vision glasses with sleek black frames on a bright mustard yellow background, showcasing the minimalist style popular in Australia." : locale === 'nz' ? "Dresden Vision's classic black frames set against a bold yellow backdrop, reflecting the stylish yet practical eyewear favored in New Zealand." : locale === 'ca' ? "Dresden Vision black glasses with a warm golden background, highlighting the simple and durable design appreciated by Canadians." : "Dresden Vision’s sophisticated black frames on a sunny yellow surface, representing the blend of fashion and function favored in the United States.";

  /* nonFirstOrderBannerAltText 
  Dresden Vision Australia: "Dresden Vision glasses on a dark background with a vibrant orange frame in front and a light blue frame with green temples behind, highlighting the diverse eyewear styles in Australia."

Dresden Vision New Zealand: "Dresden Vision frames against a sleek black surface with a bold orange pair in front and a subtle light blue and green pair peeking from the corner, showcasing the distinctive style in New Zealand."

Dresden Vision Canada: "Dresden Vision glasses on a charcoal backdrop with a lively orange pair in the center and a cool blue and green combination adding contrast, reflecting the unique style appreciated in Canada."

Dresden Vision United States: "Dresden Vision glasses on a deep black background with a striking orange frame standing out and a softer blue and green pair complementing it, demonstrating the versatile style favored in the United States."*/

  const nonFirstOrderBannerAltText = locale === 'au' ? "Dresden Vision glasses on a dark background with a vibrant orange frame in front and a light blue frame with green temples behind, highlighting the diverse eyewear styles in Australia." : locale === 'nz' ? "Dresden Vision frames against a sleek black surface with a bold orange pair in front and a subtle light blue and green pair peeking from the corner, showcasing the distinctive style in New Zealand." : locale === 'ca' ? "Dresden Vision glasses on a charcoal backdrop with a lively orange pair in the center and a cool blue and green combination adding contrast, reflecting the unique style appreciated in Canada." : "Dresden Vision glasses on a deep black background with a striking orange frame standing out and a softer blue and green pair complementing it, demonstrating the versatile style favored in the United States.";

  /*Promotion Banner Alt text
  
  Dresden Vision Australia: "A cheerful bald man with a beard, wearing Dresden Vision glasses and a light blue t-shirt, smiles broadly with his hand on his chest, showcasing the comfort and style of Australian-designed eyewear."

Dresden Vision New Zealand: "A bald man with facial hair wears Dresden Vision glasses and a pale blue tee. His smile highlights the quality and style of the eyewear in New Zealand."

Dresden Vision Canada: "A happy, bearded man with a shaved head wears Dresden Vision glasses and a light blue shirt. His warm smile and hand-to-chest pose convey the pride and comfort found in choosing eyewear in Canada."

Dresden Vision United States: "Radiating joy, a bald man with a full beard wears stylish Dresden Vision frames and a sky-blue t-shirt. His hand over his heart and wide smile reflect the appreciation for comfortable and fashionable glasses in the United States."*/

  const promotionBannerAltText = locale === 'au' ? "A cheerful bald man with a beard, wearing Dresden Vision glasses and a light blue t-shirt, smiles broadly with his hand on his chest, showcasing the comfort and style of Australian-designed eyewear." : locale === 'nz' ? "A bald man with facial hair wears Dresden Vision glasses and a pale blue tee. His smile highlights the quality and style of the eyewear in New Zealand." : locale === 'ca' ? "A happy, bearded man with a shaved head wears Dresden Vision glasses and a light blue shirt. His warm smile and hand-to-chest pose convey the pride and comfort found in choosing eyewear in Canada." : "Radiating joy, a bald man with a full beard wears stylish Dresden Vision frames and a sky-blue t-shirt. His hand over his heart and wide smile reflect the appreciation for comfortable and fashionable glasses in the United States.";
  const promoBanners = {
    firstOrderBanner: {
      backgroundImage: "https://w.dresden.vision/images/home-campaign-banners/banner_firstorderdiscount.jpg",
      mobileBackgroundImage: "https://w.dresden.vision/images/home-campaign-banners/banner_firstorderdiscount_mobile2.jpg",
      backgroundColor: "rgba(0, 0, 0, 0)",
      title: "Get 50% off your first order",
      titleColor: "#ffffff",
      subtitle: "New to Dresden? Welcome to the club! Grab a pair of prescription glasses from $50 for a limited time only. T&C’s Apply. ",
      subtitleColor: "#ffffff",
      buttonText: "Grab Your Code",
      buttonColor: "#ffffff",
      buttonTextColor: "#000000",
      buttonUrl: "/welcome50",
      altText: firstOrderBannerAltText,
      avaliableRegions: ["au", "nz", "international", "ca"]
    },
    nonFirstOrderBanner: {
      backgroundImage: "https://w.dresden.vision/images/home-campaign-banners/banner_referall.jpg",
      mobileBackgroundImage: "https://w.dresden.vision/images/home-campaign-banners/banner_referall_mobile2.jpg",
      backgroundColor: "rgba(0, 0, 0, 0)",
      title: "Want 50% off your next pair?",
      titleColor: "#ffffff",
      subtitle: "Share the love and be rewarded big time!! Grab your next order half price when you refer friends and fam!! T&C’s Apply.",
      subtitleColor: "#ffffff",
      buttonText: "Find Out How",
      buttonColor: "#ffffff",
      buttonTextColor: "#000000",
      buttonUrl: "/referral-program",
      altText: nonFirstOrderBannerAltText,
      avaliableRegions: ["au", "nz", "international", "ca"]
    },
    promotionBanners: [
      {
        backgroundImage: "https://cdn.dresden.vision/images/CampBanner.jpg",
        mobileBackgroundImage: "https://cdn.dresden.vision/images/CampBannerMobile.jpg",
        backgroundColor: "rgba(0, 0, 0, 0)",
        title: "Capture <br> both your<br>  angles",
        titleColor: "#0A723A",
        subtitle: "Be your best with 2 pairs of prescription glasses from $100 Limited time only.",
        subtitleColor: "#0A723A",
        buttonText: "Check the Deal",
        buttonColor: "#0A723A",
        buttonTextColor: "#FF94F1",
        buttonUrl: "/page/bogof",
        altText: promotionBannerAltText,
        titleWidths: "max-w-[60%] smx:max-w-[70%] xxsmx:max-w-[90%] xsmx:max-w-[90%]",
        subtitleWidths: "max-w-[40%] smx:max-w-[50%] xxsmx:max-w-[60%] xsmx:max-w-[60%]",
        avaliableRegions: ["au", "nz", "international"]
      },
      {
        backgroundImage: "https://w.dresden.vision/images/home-campaign-banners/banner_bundle.jpg",
        mobileBackgroundImage: "https://w.dresden.vision/images/home-campaign-banners/banner_bundle_mobile2.jpg",
        backgroundColor: "rgba(0, 0, 0, 0)",
        title: "Bundle & Save with 2 complete pairs from $160",
        titleColor: "#2bbfff",
        subtitle: "One pair is never enough! Mix and match with 40% off all extra pairs. T&C’s Apply.",
        subtitleColor: "#2bbfff",
        buttonText: "Find Out More",
        buttonColor: "#2bbfff",
        buttonTextColor: "#ffffff",
        buttonUrl: "/page/bundle",
        altText: promotionBannerAltText,
        avaliableRegions: ["ca"]
      }
    ]
  };

  return {
    props: {
      page: data.page,
      stores: countryList,
      seoMetaTags,
      desktopVideoUrl,
      mobileVideoUrl,
      videoAltText,
      promoBanners
    }
  };
};
